@keyframes Nd8Kjq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Nd8Kjq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Nd8Kjq_container {
  background: #34353f;
  border-radius: 16px;
  padding: 24px 24px 24px 16px;
  position: relative;
  overflow-y: auto;
}

.Nd8Kjq_container .Nd8Kjq_placeholder {
  color: #95969f;
  font: var(--font-body-2);
  position: absolute;
  inset: 24px 32px 24px 24px;
}

.Nd8Kjq_container .Nd8Kjq_copy {
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  justify-content: right;
  align-items: center;
  transition: opacity .2s ease-in-out;
  display: flex;
  position: absolute;
  top: 12px;
  right: 12px;
}

.Nd8Kjq_container:hover .Nd8Kjq_copy {
  opacity: 1;
}

.Nd8Kjq_container .Nd8Kjq_editor {
  position: relative;
  overflow-x: auto;
}

.Nd8Kjq_container .Nd8Kjq_editor textarea {
  width: 100%;
  white-space: pre;
  word-break: normal;
  resize: none;
  color: #fefefe;
  -webkit-text-fill-color: transparent;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: Roboto Mono, monospace;
  font-size: 14px;
  line-height: 1.5;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.Nd8Kjq_container .Nd8Kjq_editor:not(.Nd8Kjq_readonly) textarea, .Nd8Kjq_container .Nd8Kjq_editor:not(.Nd8Kjq_readonly) pre {
  min-height: 80px;
  display: flex;
}

.Nd8Kjq_container .Nd8Kjq_editor::-webkit-scrollbar {
  display: none;
}

.Nd8Kjq_errorMessage {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: 4px;
}

@keyframes PkjlTG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes PkjlTG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.PkjlTG_container {
  background-color: var(--color-layer-light);
  border-radius: 8px;
  flex-direction: column;
  margin: 24px 0;
  display: flex;
}

.PkjlTG_container .PkjlTG_summary {
  color: var(--color-text-secondary);
  font: var(--font-label-2);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  align-items: center;
  padding: 16px;
  display: flex;
}

.PkjlTG_container .PkjlTG_summary .PkjlTG_arrow {
  margin-right: 8px;
  transition: transform .3s;
  transform: rotate(0);
}

.PkjlTG_container .PkjlTG_details {
  color: var(--color-text);
  font: var(--font-body-2);
  border-top: 1px solid var(--color-divider);
  padding: 16px;
}

.PkjlTG_container .PkjlTG_details > p {
  margin: 0;
}

.PkjlTG_container.PkjlTG_expanded .PkjlTG_summary .PkjlTG_arrow {
  transform: rotate(90deg);
}

@keyframes PpHBxa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes PpHBxa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.PpHBxa_step {
  background-color: var(--color-layer-1);
  max-width: 858px;
  border-radius: 16px;
  align-items: center;
  margin: 0 auto;
  padding: 20px 24px;
  display: flex;
}

.PpHBxa_step .PpHBxa_index {
  background-color: var(--color-layer-2);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 16px;
  position: relative;
  overflow: hidden;
}

.PpHBxa_step .PpHBxa_index:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite PpHBxa_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.PpHBxa_step .PpHBxa_wrapper {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.PpHBxa_step .PpHBxa_wrapper .PpHBxa_title {
  background-color: var(--color-layer-2);
  width: 140px;
  height: 24px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.PpHBxa_step .PpHBxa_wrapper .PpHBxa_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite PpHBxa_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.PpHBxa_step .PpHBxa_wrapper .PpHBxa_subtitle {
  background-color: var(--color-layer-2);
  width: 400px;
  height: 20px;
  border-radius: 8px;
  margin-top: 4px;
  position: relative;
  overflow: hidden;
}

.PpHBxa_step .PpHBxa_wrapper .PpHBxa_subtitle:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite PpHBxa_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.PpHBxa_step + .PpHBxa_step {
  margin-top: 24px;
}

@keyframes j1GCcq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes j1GCcq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.j1GCcq_content {
  width: 100%;
  flex: 1;
  position: relative;
}

.j1GCcq_content section h3 {
  font: var(--font-title-2);
  color: var(--color-text-secondary);
  margin: 24px 0 12px;
}

.j1GCcq_content section p {
  font: var(--font-body-2);
  margin: 16px 0;
}

.j1GCcq_content section ul > li, .j1GCcq_content section ol > li {
  font: var(--font-body-2);
  margin-block: 8px;
  padding-inline-start: 4px;
}

.j1GCcq_content section table {
  border-spacing: 0;
  border: 1px solid var(--color-border);
  font: var(--font-body-2);
}

.j1GCcq_content section table tr {
  width: 100%;
}

.j1GCcq_content section table td, .j1GCcq_content section table th {
  padding: 8px 16px;
}

.j1GCcq_content section table thead {
  font: var(--font-title-3);
}

.j1GCcq_content section table tbody td {
  border-top: 1px solid var(--color-border);
}

.j1GCcq_content section code:not(pre > code) {
  background: var(--color-layer-2);
  font: var(--font-body-2);
  border-radius: 4px;
  padding: 4px;
}

.j1GCcq_content section hr {
  border-color: var(--color-border);
}

.j1GCcq_notFound {
  width: 100%;
}

.j1GCcq_notFound svg {
  margin-top: 10%;
}

.j1GCcq_actionBar {
  background-color: var(--color-layer-1);
  box-shadow: var(--shadow-3);
  z-index: 1;
  flex-shrink: 0;
  padding: 16px 24px;
}

.j1GCcq_actionBar .j1GCcq_layout {
  max-width: 858px;
  margin: 0 auto;
}

.j1GCcq_actionBar .j1GCcq_layout > button {
  margin-left: auto;
  margin-right: 0;
}

@keyframes SGS0fW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes SGS0fW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.SGS0fW_multilineInput > :not(:first-child) {
  margin-top: 8px;
}

.SGS0fW_multilineInput .SGS0fW_firstFieldWithMultiInputs {
  padding-right: 36px;
}

.SGS0fW_multilineInput .SGS0fW_deletableInput {
  align-items: center;
  display: flex;
}

.SGS0fW_multilineInput .SGS0fW_deletableInput > :first-child {
  flex: 1;
}

.SGS0fW_multilineInput .SGS0fW_deletableInput > :not(:first-child) {
  margin-left: 8px;
}

.SGS0fW_multilineInput .SGS0fW_minusIcon {
  color: var(--color-text-secondary);
}

.SGS0fW_multilineInput .SGS0fW_addAnother {
  margin-top: 4px;
}

.SGS0fW_multilineInput .SGS0fW_error {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: 4px;
}

@keyframes _5hpe_G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _5hpe_G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._5hpe_G_headlineWithMultiInputs {
  padding-right: 36px;
}

@keyframes _6CCQEa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _6CCQEa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._6CCQEa_wrapper {
  align-items: flex-start;
  display: flex;
  position: relative;
}

._6CCQEa_wrapper ._6CCQEa_field, ._6CCQEa_wrapper ._6CCQEa_field ._6CCQEa_multiTextInput {
  flex: 1;
}

._6CCQEa_wrapper ._6CCQEa_saveButton {
  flex-shrink: 0;
  margin: 24px 0 0 8px;
}

._6CCQEa_form {
  margin: 16px 0;
}

@keyframes RdP3iG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes RdP3iG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.RdP3iG_container {
  width: 100%;
  margin-top: 12px;
}

.RdP3iG_container ul {
  border-bottom: 1px solid var(--color-divider);
  margin: 4px 0;
  padding: 0;
  display: flex;
}

.RdP3iG_container ul li {
  font: var(--font-label-2);
  color: var(--color-text-secondary);
  margin-right: 24px;
  padding-bottom: 4px;
  cursor: pointer;
  margin-block-end: unset;
  padding-inline-start: unset;
  list-style: none;
}

.RdP3iG_container ul li[aria-selected="true"] {
  color: var(--color-text-link);
  border-bottom: 2px solid var(--color-text-link);
  outline: none;
  margin-bottom: -1px;
}

.RdP3iG_container .RdP3iG_hidden {
  display: none;
}

@keyframes HkVrGG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes HkVrGG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.HkVrGG_sample {
  border: 0 solid var(--color-divider);
  background: var(--color-surface-variant);
  border-radius: 12px;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  padding: 16px;
  display: flex;
}

.HkVrGG_sample hgroup header {
  color: var(--color-neutral-10);
  font: var(--font-label-2);
}

.HkVrGG_sample hgroup p {
  color: var(--color-neutral-50);
  font: var(--font-body-3);
  margin-top: 4px;
}

.HkVrGG_logo {
  width: 48px;
  height: 48px;
}

@keyframes wjiRLq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes wjiRLq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.wjiRLq_container {
  width: 28px;
  height: 28px;
  color: var(--color-text-link);
  background: var(--color-focused-variant);
  font: var(--font-title-2);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.wjiRLq_container.wjiRLq_active {
  color: var(--color-on-primary);
  background: var(--color-primary);
}

.wjiRLq_container.wjiRLq_completed {
  background: var(--color-primary);
}

.wjiRLq_container.wjiRLq_completed > svg {
  color: var(--color-on-primary);
}

@keyframes VqKMoq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes VqKMoq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.VqKMoq_wrapper {
  background: var(--color-layer-1);
  width: 100%;
  border-radius: 16px;
  padding: 20px 24px;
}

.VqKMoq_wrapper header {
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
  display: flex;
}

@keyframes _1SxfkG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _1SxfkG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._1SxfkG_wrapper {
  width: 100%;
}

._1SxfkG_navigationAnchor {
  position: absolute;
  inset: 24px auto 24px 24px;
}

._1SxfkG_navigation {
  width: 220px;
  flex-shrink: 0;
  margin-right: 30px;
  position: sticky;
  top: 24px;
}

._1SxfkG_navigation > :not(:last-child) {
  margin-bottom: 24px;
}

._1SxfkG_content {
  width: 100%;
  min-width: 750px;
  max-width: 1406px;
  margin: 0 auto;
  padding: 24px 274px;
  position: relative;
}

._1SxfkG_content > :not(:last-child) {
  margin-bottom: 24px;
}

._1SxfkG_content._1SxfkG_compact {
  min-width: 652px;
  padding: 0;
}

._1SxfkG_stepper {
  font: var(--font-title-2);
  color: var(--color-text);
  border: 1px solid var(--color-surface-5);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 16px;
}

._1SxfkG_stepper:hover {
  background: var(--color-surface-5);
}

._1SxfkG_stepper._1SxfkG_active {
  background: var(--color-focused-variant);
}

@media screen and (max-width: 1406px) {
  ._1SxfkG_content {
    max-width: 1156px;
    margin: 0;
    padding-right: 24px;
  }
}

/*# sourceMappingURL=README.54728ad8.css.map */
